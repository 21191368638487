import "./App.css";
import { useEffect, useState } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import MyContext from "./context/MyContext";
import ProtectedRoute from "./components/ProtectedRoute.jsx";
import {jwtDecode} from "jwt-decode";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

import Home from "./pages/Home";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import NoPage from "./pages/NoPage";

function App() {
  const [role, setRole] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          Swal.fire("Session Expired! Please Login Again.").then(() => {
            Cookies.remove("token");
            navigate("/");
          });
        } else {
          setRole(decoded.role);
        }
      } catch (error) {
        Cookies.remove("token");
        navigate("/");
      }
    } else {
      setRole(null);
    }
  }, [navigate]);

  return (
    <div className="App">
      <MyContext>
        <Routes>
          <Route path="/" element={role === null ? <Login /> : <Navigate to={role === "user" ? "/complain" : "/admin"} />} />
          <Route
            path="/complain"
            element={
              <ProtectedRoute role="user">
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <ProtectedRoute role="admin">
                <Admin />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </MyContext>
    </div>
  );
}

export default App;
