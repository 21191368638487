import React, { useState,useContext } from "react";
import context_login from '../context/Mycontextfile'

const LoginForm = ({ setLoginData }) => {
  const {login} = useContext(context_login)
  const [input, setInput] = useState({
    userpassword: ''
  })
  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value })
  }

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = (e) => {
    e.preventDefault()
    login( input.userpassword)
  }
  return (
    <div>
      {" "}
      <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
        <div>
          <label
            htmlFor="Password"
            className="flex mb-2 text-sm font-medium text-gray-900 "
          >
            Password
          </label>
          <input
            type={showPassword ? "text" : "password"}
            name="userpassword"
            id="userpassword" value={input.userpassword} onChange={handleChange}
            placeholder="••••••••"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 text-left"
            required
          />
        </div>
        <div className="flex items-start">
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="text-sm text-primary-600 hover:underline dark:text-primary-500"
          >
            {showPassword ? "Hide Password" : "Show Password"}
          </button>
        </div>
        <button
          type="submit"
          className="w-full text-white bg-blue-700 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
        >
          Login
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
