import React, { useState, useContext,useEffect} from "react";
import Context_compalin from "../context/Mycontextfile";
import { redirect } from "react-router-dom";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const ComplaintForm = () => {
  const { complain } = useContext(Context_compalin);
  const [comp, setComplaint] = useState({
    complaint: "",
    anonymous: false,
    fullname: ""
  });

  const handleChange = (e) => {
    setComplaint({ ...comp, [e.target.name]: e.target.value.replace(/\s{2,}/g, ' ') });
  };

  const handleAnonymousChange = (e) => {
    setComplaint({ ...comp, anonymous: e.target.checked });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (comp.complaint.trim() !== "") {
      complain(comp.complaint, comp.anonymous, comp.fullname);
      Swal.fire("Your Complaint has been Submitted!");
      setComplaint({
        complaint: "",
        anonymous: false,
        fullname: ""
      });
    }
  };

  const handleLogout = () => {
    Swal.fire({
      title: "Do you want to Logout?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        Cookies.remove("token");
        redirect('/');
        window.location.reload()
      }
    });
  };
  

  return (
    <>
      <div className="flex justify-around">
        <h2 className="flex justify-center font-bold uppercase">Welcome to the Complaint Box</h2>
        <img onClick={handleLogout} className="cursor-pointer" width="25" height="25" src="https://img.icons8.com/ios-filled/100/exit.png" alt="exit" />
      </div>
      <div className="max-w-md mx-auto mt-8 bg-white p-6 rounded-lg shadow-md">
        <form onSubmit={handleSubmit} className="">
          <div className="mb-4">
            <label
              htmlFor="complaint"
              className="block text-lg font-medium text-gray-800"
            >
              Complaint:
            </label>
            <textarea
              name="complaint"
              value={comp.complaint}
              onChange={handleChange}
              className="block w-full px-4 py-3 mt-2 text-lg text-gray-700 placeholder-gray-500 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
              rows="4"
              placeholder="Enter your complaint here..."
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="anonymous" className="flex items-center">
              <input
                type="checkbox"
                id="anonymous"
                name="anonymous"
                checked={comp.anonymous}
                onChange={handleAnonymousChange}
                className="form-checkbox h-5 w-5 text-blue-600"
              />
              <span className="ml-2 text-lg text-gray-800">
                Submit anonymously
              </span>
            </label>
          </div>
          {!comp.anonymous && (
            <div className="mb-4">
              <label
                htmlFor="fullname"
                className="block text-lg font-medium text-gray-800"
              >
                Your Name:
              </label>
              <input
                type="text"
                id="fullname"
                name="fullname"
                value={comp.fullname}
                onChange={handleChange}
                onBlur={(e) => setComplaint({ ...comp, [e.target.name]: e.target.value.trim() })}
                className="block w-full px-4 py-3 mt-2 text-lg text-gray-700 placeholder-gray-500 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
                placeholder="Enter your name"
                required
              />
            </div>
          )}
          <button
            type="submit"
            className="block w-full px-4 py-3 mt-4 text-lg font-semibold text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
          >
            Submit Complaint
          </button>
        </form>
      </div>
    </>
  );
};

export default ComplaintForm;
