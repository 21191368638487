import React, { useEffect, useState, useContext, useRef } from 'react';
import MyContext from '../context/Mycontextfile';
import Modal from '../components/Modal';
import { redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import Cookies from "js-cookie";


const Admin = () => {
  const { data, fetch_compalins } = useContext(MyContext);
  const [_id, setId] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const complaintsPerPage = 10;

  const toggleModal = (id) => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setId(id);
    }
    fetch_compalins();
  };

  useEffect(() => {
    const fetchData = async () => {
      if (fetch_compalins) {
        fetch_compalins();
      }
    };
    fetchData();
  }, []);

  const handleLogout = () => {
    Swal.fire({
      title: "Do you want to Logout?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        Cookies.remove('token')
        redirect('./');
        window.location.reload();
      }
    });
  };

  const reversedComplaints = [...(data.data || [])].reverse();
  const indexOfLastComplaint = currentPage * complaintsPerPage;
  const indexOfFirstComplaint = indexOfLastComplaint - complaintsPerPage;
  const currentComplaints = reversedComplaints.slice(indexOfFirstComplaint, indexOfLastComplaint);
  const totalPages = Math.ceil(reversedComplaints.length / complaintsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <div className="flex justify-around">
        <h2 className="flex justify-center font-bold uppercase">Welcome to the Admin Side</h2>
        <img onClick={handleLogout} className="cursor-pointer" width="25" height="25" src="https://img.icons8.com/ios-filled/100/exit.png" alt="exit" />
      </div>
      <div className="max-w-[62rem] mx-auto mt-8 bg-white p-6 rounded-lg shadow-md">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Sr
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Complain
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Anonymous
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Details
                </th>
              </tr>
            </thead>
            <tbody>
              {currentComplaints.map((item, index) => (
                <tr key={item._id} className="bg-white">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {index + 1 + (currentPage - 1) * complaintsPerPage}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {item.fullname}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {item.complaint}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-black">
                    {item.anonymous ? 'Yes' : 'No'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-black">
                    {item.status ? 'Viewed' : 'Pending'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <button className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      onClick={() => toggleModal(item._id)}>View Details</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {isOpen && (
            <Modal id={_id} toggleModal={toggleModal} />
          )}
        </div>
        <div className="flex justify-center mt-4">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => paginate(index + 1)}
              className={`mx-1 px-3 py-1 rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default Admin;
