import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import MyContext from "../context/Mycontextfile";
import {jwtDecode} from "jwt-decode";
import Cookies from "js-cookie";

const ProtectedRoute = ({ children, role }) => {
  const { auth } = useContext(MyContext);
  const token = Cookies.get("token");
  if (!token) {
    return <Navigate to="/" />;
  }
  try {
    const decoded = jwtDecode(token);
    if (role && decoded.role !== role) {
      return <Navigate to={decoded.role === "user" ? "/complain" : "/admin"} />;
    }
  } catch (error) {
    Cookies.remove("token");
    return <Navigate to="/" />;
  }
  return children;
};

export default ProtectedRoute;
