import React from 'react';
import {Link } from 'react-router-dom';

const NoPage = () => {

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50">
      <div className="max-w-md max-md:w-[300px] w-full rounded-lg bg-white p-8 text-center shadow-xl">
        <h1 className="mb-4 text-4xl font-bold">404</h1>
        <p className="text-gray-600 mb-4">
          Oops! The page you are looking for could not be found.
        </p>
        <Link
          to="/"
          className="inline-block rounded bg-blue-700 px-4 py-2 font-semibold text-white hover:bg-blue-600 max-md:hidden "
        >
          Go back to Home
        </Link>
      </div>
    </div>
  );
};

export default NoPage;
