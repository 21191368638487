import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

const hostdomain = process.env.REACT_APP_HOSTNAME;

const Modal = ({ id, toggleModal }) => {
  const [complaint, setComplaint] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const token = Cookies.get("token")
        const response = await fetch(`${hostdomain}/getComplaintById/${id}`, {
          method: "POST",
          headers: {
            Authorization: token ,
          },
        });
        const json = await response.json();
        setComplaint(json.data.complaint);
        setCreatedAt(json.data.created_at);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [id]);

  return (
    <div
      id="default-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="fixed inset-0 z-50 flex items-center justify-center w-full h-[calc(100%-1rem)] max-h-full overflow-y-auto overflow-x-hidden backdrop-filter backdrop-blur-sm"
    >
      <div className="absolute p-4 w-full max-w-2xl max-h-full">
        <div className="bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              Complaint
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={toggleModal}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="p-4 md:p-5 space-y-4">
            {isLoading ? (
              <div className="flex justify-center items-center">
                <svg
                  className="w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9765 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9765 100 50.5908ZM9.08137 50.5908C9.08137 73.641 27.9501 92.5098 50 92.5098C72.0499 92.5098 90.9186 73.641 90.9186 50.5908C90.9186 27.5405 72.0499 8.6718 50 8.6718C27.9501 8.6718 9.08137 27.5405 9.08137 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5532C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7236 75.2124 7.56626C69.5422 4.40887 63.2754 2.56836 56.7335 2.14333C51.7663 1.79589 46.7826 2.41948 42.0168 3.9797C39.5946 4.75862 38.1202 7.2677 38.7573 9.69323C39.3944 12.1188 41.8807 13.5914 44.3098 12.931C47.8726 11.9184 51.5417 11.6583 55.1302 12.1716C60.139 12.8777 64.9066 14.7487 69.0611 17.6235C73.2155 20.4983 76.6436 24.275 79.1016 28.7325C81.0402 32.1491 82.4626 35.8534 83.2937 39.7008C83.8605 42.168 86.4892 43.6781 88.9576 43.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </div>
            ) : (
              <>
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400 overflow-wrap break-words">
                  {complaint}
                </p>
                <div className="text-sm text-gray-400 dark:text-gray-500 flex flex-wrap justify-evenly">
                  <p className="overflow-wrap break-words">
                    Created at: {`${createdAt.slice(0, 10)}`}
                  </p>
                  <p className="overflow-wrap break-words">
                    {`${createdAt.slice(11, 13)} Hours`} {`${createdAt.slice(14, 16)} Minutes`} {`${createdAt.slice(17, 19)} Seconds`}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
