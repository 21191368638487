import React, { useState, useEffect } from "react";
import Context from "./Mycontextfile";
import { useNavigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const MyContext = ({ children }) => {
  const [auth, setAuth] = useState({
    token: Cookies.get("token"),
  });
  const navigate = useNavigate();

  const hostdomain = process.env.REACT_APP_HOSTNAME;

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setAuth({ token, role: decoded.role });
      } catch (error) {
        Cookies.remove("token");
        navigate("/");
      }
    }
  }, [navigate]);

  // Api calling for login
  const login = async (userpassword) => {
    try {
      const res = await fetch(`${hostdomain}/userLogin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({userpassword }),
      });

      const json = await res.json();

      if (res.ok && json.token) {
        Cookies.set("token", json.token);
        const decoded = jwtDecode(json.token);
        setAuth({ token: json.token, role: decoded.role });
        if (decoded.role === "user") {
          Swal.fire("Login Successful").then(() => {
            navigate("/complain");
            window.location.reload();
          });
        } else if (decoded.role === "admin") {
          Swal.fire("Login Successful").then(() => {
            navigate("/admin");
            window.location.reload();
          });
        }
      } else {
        Swal.fire("Incorrect Details, Please Try Again!");
      }
    } catch (error) {
      console.error("Error during login:", error);
      alert("An error occurred during login");
    }
  };

  // Ai calling for sending complaint
  const complain = async (complaint, anonymous, fullname) => {
    const token = Cookies.get("token");
    const res = await fetch(`${hostdomain}/createComplaint`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({ complaint, anonymous, fullname }),
    });
     await res.json();
  };

  // api calling for fetching complaint
  const [data, setData] = useState([]);
  const fetch_compalins = async () => {
    const token = Cookies.get("token");
    const response = await fetch(`${hostdomain}/getAllComplaints`, {
      method: "POST",
      headers: {
        Authorization: token,
      },
    });
    const json = await response.json();
     if(json["message"]==="invalid header"){
      Swal.fire("Unauthorized");
      Cookies.remove('token')
      navigate('/')
     }
    setData(json);
  };

  return (
    <Context.Provider value={{ login, data, complain, fetch_compalins, auth }}>
      {children}
    </Context.Provider>
  );
};

export default MyContext;
